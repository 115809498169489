<template>
  <div>
    <h5 class="mb3">{{ dados.nomeProduto }} - {{ dados.valor | currency }}</h5>
    <hr />

    <div class="vx-row mb-4" v-if="tiposDeDescontos.length > 0">
      <b-form-group
        v-for="item in tiposDeDescontos"
        :key="item.id"
        id="checkbox-group-2"
        name="flavour-2"
      >
        <b-form-radio
          @change="alterarSomente1(item)"
          v-model="form.idTipoDesconto"
          name="tipoDesconto"
          :value="item.id"
          >{{ item.label }}</b-form-radio
        >
        <!--b-form-checkbox
              v-model="item.selecionado"
              class="w-100 mb-3"
              size="lg"
              @change="alterarSomente1(item)"
            >
              <b class="mr-2">{{ item.label }} {{item.selecionado}}</b>
            </b-form-checkbox-->
      </b-form-group>
    </div>

    <div class="mb-4">
      <span class="textoModal mb-3">Selecione a Forma de aplicação:</span>
      <b-form-select
        class="mt-2"
        :options="tipoDesconto"
        v-model="form.tipoDesconto"
        name="Tipo Desconto"
        id="Tipo Desconto"
        v-validate="{ required: true }"
      ></b-form-select>
    </div>

    <div v-if="form.tipoDesconto == 'Desconto por Valor'">
      <label>Máximo de {{ descontoValor | currency }}</label>
      <b-input-group prepend="R$" class="mt-3">
        <!--b-form-input
                type="number"
                v-model="form.valorDesconto"
                :placeholder="`Máximo de R$${descontoValor}`"
                id="Desconto"
                name="Desconto"
                ></b-form-input-->

        <money
          class="form-control"
          name="Desconto"
          id="Desconto"
          :placeholder="`Máximo de ${descontoValor | currency}`"
          v-model="form.valorDesconto"
          v-validate="{ required: true, min_value: 0.01 }"
          :state="validateState('Desconto')"
          :danger="validateClass('Desconto')"
          v-bind="money"
        ></money>
      </b-input-group>
    </div>

    <div v-if="form.tipoDesconto == 'Desconto por Porcentagem'">
      <label>Até {{ porcentagem }}%</label>
      <b-input-group prepend="%" class="mt-3">
        <!--b-form-input
                    type="number"
                    v-model="form.valorDesconto"
                    :placeholder="`Até ${dados.porcentagemDescontoMaxima}%`"
                    id="Desconto"
                    name="Desconto"
                ></b-form-input-->

        <money
          class="form-control"
          name="Desconto"
          id="Desconto"
          :placeholder="`Até ${porcentagem}%`"
          v-model="form.valorDesconto"
          v-validate="{ required: true, min_value: 0.01 }"
          :state="validateState('Desconto')"
          :danger="validateClass('Desconto')"
          v-bind="money"
        ></money>
      </b-input-group>
    </div>

    <div class="mt-5">
      <b-input-group-append>
        <button
          v-if="tiposDeDescontos.length > 0"
          style="width: 100%"
          type="button"
          @click="onSubmit(dados)"
          class="btn btn-success"
        >
          dar desconto
        </button>
      </b-input-group-append>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import locale from './../locale';
import service from '../services';
import { Money } from 'v-money';
export default {
  name: 'DashBoard',
  props: {
    temaCor: Object,
    formCompleto: Boolean,
    idImplemento: Number,
    dados: Object,
    contModal: Number,
    escondeCobertura: Boolean,
  },
  watch: {
    dados() {
      if (this.dados) {
        this.buscaTipoDescontoProduto();
        this.preparaDescontos();
        this.alterarSomente1();
        this.produtoToDesconto = this.dados;
        this.preencheForm();
      }
    },
    contModal() {
      this.preparaDescontos();
      this.produtoToDesconto = this.dados;
      this.preencheForm();
    },
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      tipoDesconto: ['Desconto por Valor', 'Desconto por Porcentagem'],
      decoded: {},
      menuOpen: false,
      carregando: false,
      form: {
        tipoDesconto: 'Desconto por Valor',
        tiposDescontos: [],
        idTipoDesconto: 0,
      },
      descontoValor: 0,
      produtoToDesconto: null,
      tiposDeDescontos: [],
      porcentagem: 0,
      label: {},
      selecionado: false,
    };
  },
  components: { Money },
  methods: {
    preencheForm() {
      if (this.dados.valorDesconto) {
        this.form.valorDesconto = this.dados.descontoConcedido;
      }
    },
    preparaDescontos() {
      console.log(this.dados.valor, this.dados.porcentagemDescontoMaxima);
      this.descontoValor = (
        (this.dados.valor * this.dados.porcentagemDescontoMaxima) /
        100
      ).toFixed(2);
      this.valorIncorreto = false;
    },
    formataMoeda(valor) {
      if (valor != null) return `R$ ${valor.toFixed(2)} `;
    },
    formataStringData(data) {
      var dia = data.split('/')[0];
      var mes = data.split('/')[1];
      var ano = data.split('/')[2];

      //return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
      return [ano, mes, dia];
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    },
    alterarSomente1(item) {
      this.porcentagem = item.porcentagem;
      this.idDesconto = item.id;

      this.descontoValor = parseFloat(
        (this.dados.valor * this.porcentagem) / 100,
      );
      console.log(this.tiposDeDescontos, item);

      this.form.valorDesconto = 0;

      this.tiposDeDescontos.forEach((element) => {
        if (element.id == item.id) {
          console.log(element, item);

          item.selecionado = true;
          this.form.valorDesconto = 0;
        } else {
          element.selecionado = false;
          this.form.valorDesconto = 0;
        }
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    buscaTipoDescontoProduto() {
      const objSend = {
        estado: this.dados.estado ? this.dados.estado : '',
        tipoProposta: this.dados.tipoProposta ? this.dados.tipoProposta : '',
        idProduto: this.dados.id_produto,
      };
      service
        .post('Cotacao', 'buscaTiposDescontosProduto', objSend)
        .then((res) => {
          res.data.forEach((element) => {
            if (element.idTipoDesconto == 0) {
              element.selecionado = true;
            } else {
              element.selecionado = false;
            }

            this.tiposDeDescontos.push({
              id: element.idTipoDesconto,
              label: element.tipoDesconto,
              porcentagem: element.porcentagemDescontoMaxima,
              selecionado: element.selecionado,
            });

            this.label = this.tiposDeDescontos.label;

            this.idDesconto = 0;
            this.porcentagem = this.tiposDeDescontos[0].porcentagem;

            this.form.valorDesconto = 0;
            this.descontoValor = parseFloat(
              (this.dados.valor * this.porcentagem) / 100,
            );
          });
        })
        .catch((e) => {
          console.error(e);
          this.erroConexao =
            'Seu aplicativo parece estar offline, verifique sua conexao';
        });
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit(dados) {
      if (this.form.tipoDesconto == 'Desconto por Valor') {
        if (this.form.valorDesconto > this.descontoValor) {
          console.log(this.form.valorDesconto, this.descontoValor);
          this.$bvToast.toast(
            `Insira um valor de Desconto até R$${this.descontoValor}`,
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            },
          );
          return;
        } else {
          dados.valorDesconto = this.form.valorDesconto;
          dados.valorFinal = dados.valor - this.form.valorDesconto;
          dados.tipoDesconto = this.form.tipoDesconto;
          dados.idTipoDesconto = this.idDesconto;
          dados.descontoConcedido =
            (this.form.valorDesconto * 100) / dados.valor;
          console.log(dados, (this.form.valorDesconto * 100) / dados.valor);
          this.valorIncorreto = false;
          this.$emit('salvarDesconto', dados);
        }
      }
      if (this.form.tipoDesconto == 'Desconto por Porcentagem') {
        if (this.form.valorDesconto > this.porcentagem) {
          this.$bvToast.toast(
            `Insira um valor de Desconto até ${this.porcentagem} %`,
            {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            },
          );
          return;
        } else {
          dados.valorDesconto = this.form.valorDesconto;
          dados.idTipoDesconto = this.idDesconto;
          dados.valorFinal =
            dados.valor - (dados.valor * this.form.valorDesconto) / 100;
          dados.tipoDesconto = this.form.tipoDesconto;
          dados.descontoConcedido = Number(this.form.valorDesconto);
          this.valorIncorreto = false;
          this.$emit('salvarDesconto', dados);
        }
      }
      this.preencheForm();
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.form.tipoDesconto = 'Desconto por Valor';
  },
  created() {
    this.preparaDescontos();
    this.buscaTipoDescontoProduto();
    this.form.tipoDesconto = 'Desconto por Valor';
  },
};
</script>
<style lang="scss" scoped>
.textoModal {
  font-size: 1.2rem;
}
</style>
