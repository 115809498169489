import { render, staticRenderFns } from "./FormDesconto.vue?vue&type=template&id=3fdccb26&scoped=true&"
import script from "./FormDesconto.vue?vue&type=script&lang=js&"
export * from "./FormDesconto.vue?vue&type=script&lang=js&"
import style0 from "./FormDesconto.vue?vue&type=style&index=0&id=3fdccb26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fdccb26",
  null
  
)

export default component.exports